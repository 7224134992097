<template>
	<b-modal
		ref="modal"
		:title="$t('modal.model_chooser.title')"
		:cancel-title="$t('modal.general.annuler')"
		:ok-title="$t('modal.general.ok')"
		:ok-disabled="model == null"
		@ok="onSubmit"
	>
		<div class="form-group">
			<label>{{ $t('modal.model_chooser.model') }} *</label>

			<e-select
				v-model="model"
				:options="models"
				track-by="model_label"
				label="model_label"
			/>
		</div>
	</b-modal>
</template>

<script>
import Model from '@/mixins/Model'

export default {
	name: 'ModelChooser',
	mixins: [Model],
	data: () => ({
		modelable_id: null,
		modelable_types: null,
		modeltype_template: null,
		models: [],
		model: null
	}),
	methods: {
		async init_component() {
			this.models = await this.loadAllModel(this.modelable_id, this.modelable_types, this.modeltype_template)
			this.model = this.models.find(model => model.model_default)
		},
		open(modelable_id, modelable_types, template=null) {
			this.modelable_id = modelable_id
			this.modelable_types = modelable_types
			this.modeltype_template = template

			this.$refs.modal.show()
			this.init_component()
		},
		onSubmit() {
			this.$emit('submit', this.model)
		}
	}
}
</script>
